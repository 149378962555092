<template>
    <component
        :is="pickerComponent"
        ref="pickerComponent"
        :value="value"
        :location-id="locationId"
        :course-id="courseId"
        :curriculum-id="curriculumId"
        :form-start-lesson-slot-configs="filteredConfigs"
        @input="$emit('input', $event)"
    ></component>
</template>
<script>
import GenericDaySlots from '@/components/StartLessonSlots/GenericDaySlots';
import SpecificTimeSlots from '@/components/StartLessonSlots/SpecificTimeSlots';

export default {
    name: 'StartLessonSlotPicker',

    components: {
        GenericDaySlots,
        SpecificTimeSlots,
    },

    props: {
        value: { type: Object, required: true },
        startLessonSlotMode: { type: String, required: true },
        locationId: { type: String, required: true },
        courseId: { type: String, required: true },
        curriculumId: { type: String, required: true },
        formStartLessonSlotConfigs: { type: Array, required: true },
        formStartLessonSlots: { type: Array, default: () => [] },
    },

    computed: {
        pickerComponent() {
            return {
                generic_day_slots: GenericDaySlots,
                specific_time_slots: SpecificTimeSlots,
            }[this.startLessonSlotMode];
        },
        filteredConfigs() {
            return this.formStartLessonSlotConfigs.filter(
                c =>
                    c.location_id === this.locationId &&
                    c.course_id === this.courseId &&
                    c.mode === this.startLessonSlotMode
            );
        },
    },

    methods: {
        resetMutations() {
            this.$refs.pickerComponent.resetMutations();
        },
    },
};
</script>
