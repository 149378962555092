<template>
    <div
        :class="rowClasses"
        v-bind="hasErrors ? { 'data-is-invalid': errorKey } : {}"
    >
        <label v-if="label" v-bind="labelAttr">
            <FaIcon
                v-if="hasErrors"
                :icon="['fas', 'exclamation-triangle']"
                :class="$css('ws-text-danger')"
            />
            {{ label }}
        </label>

        <div :class="[stacked ? '' : $css('ws-col-md-2/3')]">
            <template v-if="type === 'slot'">
                <slot :has-errors="hasErrors"></slot>
            </template>
            <input
                v-else
                :value="value"
                v-bind="inputAttr"
                @input="onInput($event.target.value)"
            />

            <p
                v-for="(errorMessage, i) in fieldErrors"
                :key="`error-${id}-${i}`"
                :class="`${$css('ws-my-2')} ${$css('ws-text-danger')}`"
                v-text="errorMessage"
            ></p>
            <p v-if="help" :class="$css('ws-my-2')">
                <small v-text="help"></small>
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FormRow',

    props: {
        value: [String, Number],
        errorKey: { type: String, default: '' },
        errors: { type: Object, default: () => ({}) },
        type: { type: String, default: 'slot' },
        id: { type: String, default: '' },
        label: { type: String, default: '' },
        help: { type: String, default: '' },
        required: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        autofocus: { type: Boolean, default: false },
        placeholder: { type: String, default: '' },
        maxLength: { type: Number, default: 0 },
        step: { type: Number, default: 1 },
        min: { type: Number, default: null },
        max: { type: Number, default: null },
        rowClass: { type: String, default: 'ws-form-group' },
        inputClass: { type: String, default: '' },
        stacked: { type: Boolean, default: false },
    },

    computed: {
        rowClasses() {
            const classes = [
                this.$css(this.rowClass),
                this.label.toLowerCase().replace(/\s/g, '-'),
            ];
            if (!this.stacked) {
                classes.push(this.$css('ws-grid'));
            }
            return classes;
        },
        labelAttr() {
            const attr = {
                class: [this.$css('ws-form-label')],
            };
            if (!this.stacked) {
                attr.class.push(this.$css('ws-col-md-1/3'));
            }
            if (this.id) {
                attr.for = this.id;
            }
            return attr;
        },
        inputAttr() {
            const attr = {
                class: [this.inputClass, this.$css('ws-input')],
                type: this.type,
                required: this.required,
                autofocus: this.autofocus,
                disabled: this.disabled,
            };
            if (this.placeholder) {
                attr.placeholder = this.placeholder;
            }
            if (this.id || attr.name) {
                attr.id = this.id;
                attr.name = this.name || this.id;
            }
            if (this.type === 'password') {
                attr.autocomplete = 'new-password';
            }
            if (this.type === 'number') {
                // attr.number = true;
                attr.step = this.step;
                if (this.min !== null) {
                    attr.min = this.min;
                }
                if (this.max !== null) {
                    attr.max = this.max;
                }
                attr.class.push('ws-text-right');
            }
            if (this.maxLength > 0) {
                attr.maxLength = this.maxLength;
            }
            if (this.hasErrors) {
                attr.class.push('ws-is-invalid');
            }
            return attr;
        },
        fieldErrors() {
            return this.errorKey ? this.errors[this.errorKey] || [] : [];
        },
        hasErrors() {
            return this.fieldErrors.length > 0;
        },
    },

    methods: {
        onInput(value) {
            if (this.type === 'number') {
                value = Number(value);
            }
            this.$emit('input', value);
        },
    },
};
</script>
