<template>
    <p v-if="showPayment">
        <img
            v-if="paymentLogoUrl"
            :src="paymentLogoUrl"
            style="height: 40px; float: left; margin-right: 5px"
            alt="Direct betalen"
        />
        {{ result.transaction.specifications.message }}
        {{ result.transaction.amount | currency }}<br />
        <LinkText
            :url="result.transaction.payment_url"
            text-key="after_submit.link.pay_online_text"
            link-key="after_submit.link.pay_online_text_link"
        />
    </p>
</template>
<script>
import IdealLogo from '../../assets/images/ideal-logo.svg';
import BancontactLogo from '../../assets/images/bancontact-logo.svg';
import LinkText from '@/components/LinkText';

export default {
    name: 'PaymentDisplay',

    components: {
        LinkText,
    },

    filters: {
        currency(amount, currency = 'EUR') {
            return Number(amount).toLocaleString([], {
                style: 'currency',
                currency,
                currencyDisplay: 'symbol',
            });
        },
    },

    props: {
        result: Object,
        form: Object,
    },

    data: () => ({
        paymentLogos: {
            ideal: IdealLogo,
            bancontact: BancontactLogo,
        },
    }),

    computed: {
        showPayment() {
            return (
                this.result.transaction &&
                this.result.transaction.payment_url &&
                this.form.settings.transaction_processing.includes(
                    'show_payment_in_confirm'
                )
            );
        },
        paymentLogoUrl() {
            if (
                this.result.transaction &&
                this.paymentLogos[this.result.transaction.payment_method]
            ) {
                return (
                    this.$webRoot +
                    this.paymentLogos[this.result.transaction.payment_method]
                );
            }
            return null;
        },
    },
};
</script>
