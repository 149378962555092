import moment from 'moment/moment';

export default {
    computed: {
        minBirthDate() {
            if (this.maxAge.years === null && this.maxAge.months === null) {
                return moment().subtract(99, 'years').startOf('day');
            }
            return moment()
                .subtract(this.maxAge.years, 'years')
                .subtract(this.maxAge.months, 'months')
                .startOf('day');
        },
        maxBirthDate() {
            let date = moment().endOf('day');
            if (this.minAge.years === null && this.minAge.months === null) {
                return date;
            }
            return date
                .subtract(this.minAge.years, 'years')
                .subtract(this.minAge.months, 'months');
        },
    },

    methods: {
        birthDateValidationError() {
            const birthDate = moment(this.pupil?.birth_date || this.value);
            let error = null;
            if (!birthDate) {
                error = this.$trans(
                    'Geboortedatum is ongeldig',
                    'error.birth_date_invalid'
                );
            } else if (birthDate > this.maxBirthDate) {
                if (this.minAge.years === null && this.minAge.months === null) {
                    error = this.$trans(
                        'Geboortedatum mag niet in de toekomst liggen',
                        'error.birth_date_not_in_future',
                        this.minAge
                    );
                } else if (this.minAge.months) {
                    error = this.$trans(
                        'Leerling is nog te jong (minimale leeftijd: %years% jaar, %months% maanden)',
                        'error.birth_date_too_young_years_months',
                        this.minAge
                    );
                } else {
                    error = this.$trans(
                        'Leerling is nog te jong (minimale leeftijd: %years% jaar)',
                        'error.birth_date_too_young_years',
                        this.minAge
                    );
                }
            } else if (birthDate < this.minBirthDate) {
                if (this.maxAge.months) {
                    error = this.$trans(
                        'Leerling is te oud (maximale leeftijd: %years% jaar, %months% maanden)',
                        'error.birth_date_too_old_years_months',
                        this.maxAge
                    );
                } else {
                    error = this.$trans(
                        'Leerling is te oud (maximale leeftijd: %years% jaar)',
                        'error.birth_date_too_old_years',
                        this.maxAge
                    );
                }
            }
            return error;
        },
    },
};
