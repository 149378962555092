<template>
    <div :class="$css('ws-grid')">
        <div :class="$css('ws-col-1/3')">
            <select
                v-model="day"
                :class="$css('ws-select')"
                :required="required"
            >
                <option
                    value=""
                    disabled
                    hidden
                    v-text="$trans('Dag', 'form.birth_date.option.day')"
                ></option>
                <option
                    v-for="dayNr in 31"
                    :key="`day${dayNr}`"
                    :value="dayNr"
                    v-text="dayNr"
                ></option>
            </select>
        </div>
        <div :class="$css('ws-col-1/3')">
            <select
                v-model="month"
                :class="$css('ws-select')"
                :required="required"
            >
                <option
                    value=""
                    disabled
                    hidden
                    v-text="$trans('Maand', 'form.birth_date.option.month')"
                ></option>
                <option
                    v-for="{ value: val, text } in months"
                    :key="val"
                    :value="val"
                    v-text="text"
                ></option>
            </select>
        </div>
        <div :class="$css('ws-col-1/3')">
            <select
                v-model="year"
                :class="$css('ws-select')"
                :required="required"
            >
                <option
                    value=""
                    disabled
                    hidden
                    v-text="$trans('Jaar', 'form.birth_date.option.year')"
                ></option>
                <option
                    v-for="yr in years"
                    :key="`year${yr}`"
                    :value="yr"
                    v-text="yr"
                ></option>
            </select>
        </div>
        <div
            v-if="!fieldErrors.length && errorMessage"
            :class="$css('ws-text-danger')"
            v-text="errorMessage"
        ></div>
    </div>
</template>
<script>
import moment from 'moment';
import BirthDateValidator from '@/mixins/BirthDateValidator';

export default {
    name: 'BirthDate',

    props: {
        value: { type: String, default: () => '' },
        minAge: {
            type: Object,
            default: () => ({ years: null, months: null }),
        },
        maxAge: {
            type: Object,
            default: () => ({ years: null, months: null }),
        },
        required: { type: Boolean, default: false },
        fieldErrors: { type: Array, default: () => [] },
    },

    mixins: [BirthDateValidator],

    data() {
        return {
            mutated: moment(),
            input: {
                day: '',
                month: '',
                year: '',
            },
            months: [
                {
                    value: 1,
                    text: this.$trans(
                        'Januari',
                        'form.birth_date.month.01_januari'
                    ),
                },
                {
                    value: 2,
                    text: this.$trans(
                        'Februari',
                        'form.birth_date.month.02_februari'
                    ),
                },
                {
                    value: 3,
                    text: this.$trans(
                        'Maart',
                        'form.birth_date.month.03_march'
                    ),
                },
                {
                    value: 4,
                    text: this.$trans(
                        'April',
                        'form.birth_date.month.04_april'
                    ),
                },
                {
                    value: 5,
                    text: this.$trans('Mei', 'form.birth_date.month.05_may'),
                },
                {
                    value: 6,
                    text: this.$trans('Juni', 'form.birth_date.month.06_june'),
                },
                {
                    value: 7,
                    text: this.$trans('Juli', 'form.birth_date.month.07_july'),
                },
                {
                    value: 8,
                    text: this.$trans(
                        'Augustus',
                        'form.birth_date.month.08_august'
                    ),
                },
                {
                    value: 9,
                    text: this.$trans(
                        'September',
                        'form.birth_date.month.09_september'
                    ),
                },
                {
                    value: 10,
                    text: this.$trans(
                        'Oktober',
                        'form.birth_date.month.10_october'
                    ),
                },
                {
                    value: 11,
                    text: this.$trans(
                        'November',
                        'form.birth_date.month.11_november'
                    ),
                },
                {
                    value: 12,
                    text: this.$trans(
                        'December',
                        'form.birth_date.month.12_december'
                    ),
                },
            ],
        };
    },

    computed: {
        errorMessage() {
            return this.birthDateValidationError();
        },
        years() {
            const years = [];
            const start = this.minBirthDate.year();
            const end = Math.max(start, this.maxBirthDate.year());
            for (let year = end; year >= start; year--) {
                years.push(year);
            }
            return years;
        },
        day: {
            get() {
                return this.input.day;
            },
            set(value) {
                this.input.day = value;
                this.mutated.date(Number(value));
            },
        },
        month: {
            get() {
                return this.input.month;
            },
            set(value) {
                this.input.month = value;
                this.mutated.month(Number(value) - 1);
            },
        },
        year: {
            get() {
                return this.input.year;
            },
            set(value) {
                this.input.year = value;
                this.mutated.year(Number(value));
            },
        },
    },

    created() {
        if (this.value) {
            this.mutated = moment(this.value);
            this.input.day = this.mutated.date();
            this.input.month = this.mutated.month() + 1;
            this.input.year = this.mutated.year();
        }
        this.$watch('input', this.emitBirthDate, { deep: true });
    },

    methods: {
        emitBirthDate() {
            if (!this.day || !this.month || !this.year) {
                return;
            }
            this.$emit('input', this.mutated.format('YYYY-MM-DD'));
        },
    },
};
</script>
