import nl from '../../lang/nl.json';
import en from '../../lang/en.json';
import fr from '../../lang/fr.json';
import de from '../../lang/de.json';

const baseLanguages = { nl, en, fr, de };
class Translator {
    constructor({ language, language_strings }) {
        let base_language = baseLanguages[language] || nl;
        this.strings = { ...base_language, ...language_strings };
        this.varsRegex = new RegExp('%(\\w*?)%', 'g');
    }

    translate(defaultString, key = '', vars = {}) {
        return this.replace(this.find(defaultString, key), vars);
    }

    find(defaultString, key = '') {
        return this.strings[key || defaultString] || defaultString;
    }

    replace(str, vars = {}) {
        let replaced = str;
        // eslint-disable-next-line no-cond-assign
        for (let part; (part = this.varsRegex.exec(str)); ) {
            const [match, key] = part;
            replaced = replaced.replace(
                match,
                vars[key] !== undefined ? vars[key] : ''
            );
        }
        return replaced;
    }
}

const TranslateFilter = {
    install(Vue, options) {
        const translator = new Translator(options);

        Vue.$trans = (str, key, vars = {}) =>
            translator.translate(str, key, vars);
        Vue.prototype.$trans = (str, key, vars = {}) =>
            translator.translate(str, key, vars);
        Vue.filter('trans', (str, key, vars = {}) =>
            translator.translate(str, key, vars)
        );
    },
};

export default TranslateFilter;
