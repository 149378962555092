<template>
    <div>
        <div :class="$css('ws-grid')">
            <div :class="$css('ws-col-md-1/2')">
                <FormRow
                    :label="$trans('Type abonnement', 'financial.subscription')"
                    :errors="errors"
                    error-key="subscription_id"
                    stacked
                >
                    <select
                        v-model="subscription_id"
                        :class="$css('ws-select')"
                        required
                    >
                        <option
                            value=""
                            hidden="hidden"
                            disabled="disabled"
                            v-text="
                                $trans(
                                    'Kies een abonnement',
                                    'financial.choose_subscription'
                                )
                            "
                        ></option>
                        <option
                            v-for="subscription in subscriptionOptions"
                            :key="subscription.value"
                            :value="subscription.value"
                            v-text="subscription.text"
                        ></option>
                    </select>
                </FormRow>
            </div>
            <div :class="$css('ws-col-md-1/2')">
                <FormRow
                    v-if="!existingCustomer"
                    :label="$trans('Betaalmethode', 'financial.payment_method')"
                    :errors="errors"
                    error-key="payment_method"
                    stacked
                >
                    <select
                        v-model="payment_method"
                        :disabled="!subscription_id"
                        :class="$css('ws-select')"
                        required
                    >
                        <option
                            value=""
                            hidden="hidden"
                            disabled="disabled"
                            v-text="
                                $trans(
                                    'Kies een betaalmethode',
                                    'financial.choose_payment_method'
                                )
                            "
                        ></option>
                        <option
                            v-for="paymentMethod in paymentMethodOptions"
                            :key="paymentMethod.value"
                            :value="paymentMethod.value"
                            v-text="paymentMethod.text"
                        ></option>
                    </select>
                </FormRow>
            </div>
        </div>
        <div
            v-if="!existingCustomer && payment_method === 'direct_debit'"
            :class="$css('ws-grid')"
        >
            <div :class="$css('ws-col-md-1/2')">
                <FormRow
                    v-model="account_iban"
                    :label="
                        $trans('IBAN rekeningnummer', 'financial.account_iban')
                    "
                    :errors="errors"
                    error-key="account_iban"
                    type="text"
                    required
                    stacked
                ></FormRow>
            </div>
            <div :class="$css('ws-col-md-1/2')">
                <FormRow
                    v-model="account_name"
                    :label="$trans('Bank t.n.v.', 'financial.account_name')"
                    :errors="errors"
                    error-key="account_name"
                    type="text"
                    stacked
                ></FormRow>
            </div>
        </div>
    </div>
</template>
<script>
import FormRow from '@/components/FormRow.vue';

export default {
    name: 'FinancialForm',

    components: {
        FormRow,
    },

    props: {
        value: Object,
        form: Object,
        curriculumRelation: [Object, null],
        subscriptionRelation: [Object, null],
        subscriptionId: [Number, String],
        errors: Object,
        existingCustomer: Boolean,
    },

    data() {
        return {
            paymentMethods: [
                {
                    value: 'direct_debit',
                    text: this.$trans(
                        'Automatische incasso',
                        'financial.payment.direct_debit'
                    ),
                },
                {
                    value: 'cash',
                    text: this.$trans('Contant', 'financial.payment.cash'),
                },
                {
                    value: 'ideal',
                    text: this.$trans('iDEAL', 'financial.payment.ideal'),
                },
                {
                    value: 'bancontact',
                    text: this.$trans(
                        'Bancontact',
                        'financial.payment.bancontact'
                    ),
                },
                {
                    value: 'external',
                    text: this.$trans(
                        'Externe financiering',
                        'financial.payment.external'
                    ),
                },
                {
                    value: 'manual',
                    text: this.$trans('Handmatig', 'financial.payment.manual'),
                },
            ],
        };
    },

    computed: {
        subscription_id: {
            get() {
                return this.subscriptionId;
            },
            set(subscription_id) {
                this.$emit('input:subscription_id', subscription_id);
            },
        },
        payment_method: {
            get() {
                return this.value.payment_method;
            },
            set(payment_method) {
                this.$emit('input', { ...this.value, payment_method });
            },
        },
        account_iban: {
            get() {
                return this.value.account_iban;
            },
            set(account_iban) {
                this.$emit('input', { ...this.value, account_iban });
            },
        },
        account_name: {
            get() {
                return this.value.account_name;
            },
            set(account_name) {
                this.$emit('input', { ...this.value, account_name });
            },
        },
        subscriptionOptions() {
            if (this.curriculumRelation) {
                return this.mapSubscriptionOptions(
                    this.form.subscriptions.filter(s => {
                        return (
                            this.curriculumRelation.allowedValues.includes(
                                String(s.id)
                            ) && this.filterSubscriptionOption(s)
                        );
                    })
                );
            }
            return this.mapSubscriptionOptions(this.form.subscriptions).filter(
                this.filterSubscriptionOption
            );
        },
        paymentMethodOptions() {
            if (this.subscriptionRelation) {
                return this.mapPaymentOptions(
                    this.paymentMethods.filter(pm => {
                        return this.subscriptionRelation.allowedValues.includes(
                            pm.value
                        );
                    })
                );
            }
            return this.mapPaymentOptions(
                this.paymentMethods.filter(
                    pm => !['external', 'manual'].includes(pm.value)
                )
            );
        },
    },

    watch: {
        subscriptionOptions() {
            if (
                !this.subscriptionOptions
                    .map(o => o.value)
                    .includes(this.subscription_id)
            ) {
                this.subscription_id =
                    this.subscriptionOptions.length === 1
                        ? this.subscriptionOptions[0].value
                        : '';
            }
        },
        paymentMethodOptions() {
            if (
                !this.paymentMethodOptions
                    .map(o => o.value)
                    .includes(this.payment_method)
            ) {
                this.payment_method =
                    this.paymentMethodOptions.length === 1
                        ? this.paymentMethodOptions[0].value
                        : '';
            }
        },
    },

    methods: {
        mapSubscriptionOptions(subscriptions) {
            if (window.weScoreMapSubscriptionOptions) {
                return window.weScoreMapSubscriptionOptions.call(
                    this.$parent,
                    subscriptions
                );
            }
            return subscriptions.map(s => ({ value: s.id, text: s.name }));
        },
        mapPaymentOptions(payment_methods) {
            if (window.weScoreMapPaymentOptions) {
                return window.weScoreMapPaymentOptions.call(
                    this.$parent,
                    payment_methods
                );
            }
            return payment_methods;
        },
        filterSubscriptionOption(subscription) {
            if (window.weScoreFilterSubscriptionOption) {
                return window.weScoreFilterSubscriptionOption.call(
                    this.$parent,
                    subscription
                );
            }
            return true;
        },
    },
};
</script>
