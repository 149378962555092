export function dayLabels($vm) {
    return {
        day1: $vm.$trans('Maandag', 'form.day.1_monday'),
        day2: $vm.$trans('Dinsdag', 'form.day.2_tuesday'),
        day3: $vm.$trans('Woensdag', 'form.day.3_wednesday'),
        day4: $vm.$trans('Donderdag', 'form.day.4_thursday'),
        day5: $vm.$trans('Vrijdag', 'form.day.5_friday'),
        day6: $vm.$trans('Zaterdag', 'form.day.6_saturday'),
        day7: $vm.$trans('Zondag', 'form.day.7_sunday'),
    };
}

export function daySlotLabels($vm, fullDay = true) {
    const labels = {
        '00:00-12:00': $vm.$trans('ochtend', 'form.day_part.morning'),
        '12:00-18:00': $vm.$trans('middag', 'form.day_part.afternoon'),
        '18:00-00:00': $vm.$trans('avond', 'form.day_part.evening'),
    };
    if (fullDay) {
        labels['00:00-00:00'] = $vm.$trans('hele dag', 'form.day_part.full_day');
    }
    return labels;
}

export function pupilStartLessonSlotLabel($vm, pupilStartLessonSlot) {
    return (
        daySlotLabels($vm)[
            `${pupilStartLessonSlot.time_from}-${pupilStartLessonSlot.time_till}`
        ] || timeLabel(pupilStartLessonSlot)
    );
}

export function timeLabel(startLessonSlot) {
    const trim = s => s.replace(/^0/, '');
    return `${trim(startLessonSlot.time_from)}-${trim(startLessonSlot.time_till)}`;
}
