import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';

function addOrReplaceItemInArray(array, item, id_key = 'id') {
    const index = array.findIndex(o => o[id_key] === item[id_key]);
    if (index === -1) {
        return [...array, item];
    }
    return arrayWithReplacedItem(array, index, item);
}

function arrayWithReplacedItem(array, index, item) {
    const copy = array.slice();
    copy.splice(index, 1, item);
    return copy;
}

function arrayWithRemovedItem(array, index) {
    if (index === -1) {
        //allow passing in invalid index directly
        return array;
    }
    const copy = array.slice();
    copy.splice(index, 1);
    return copy;
}

export default {
    props: {
        value: { type: Object, default: () => ({ current: [] }) },
        errors: { type: Object, default: () => ({}) },
    },

    data: () => ({
        //we need to keep a copy of the input, to keep track of new items' temp_ids, which shoud not be exposed to value
        input: {
            current: [],
            create: [],
            update: [],
            delete: [],
        },
        omitKeys: [],
    }),

    computed: {
        orderedValues() {
            return sortBy(this.filteredValues, ['ordering']);
        },
        filteredValues() {
            return this.value.current;
        },
    },

    methods: {
        resetMutations() {
            this.input = {
                create: [],
                update: [],
                delete: [],
            };
        },
        getNextOrdering() {
            let ordering = 0;
            if (this.value.current.length) {
                ordering =
                    this.value.current.reduce(
                        (max, i) => Math.max(max, i.ordering),
                        0
                    ) + 1;
            }
            return ordering;
        },
        applyOrdering(ordering) {
            const current = []; //remake the current in new array, we need the old ordering for find
            for (const [previousOrdering, newOrdering] of Object.entries(
                ordering
            )) {
                const item = this.value.current.find(
                    o => o.ordering === Number(previousOrdering)
                );
                const updated = { ...item, ordering: newOrdering };
                current.push(updated);
                if (updated.temp_id) {
                    this.input.create = addOrReplaceItemInArray(
                        this.input.create,
                        updated,
                        'temp_id'
                    );
                } else {
                    this.input.update = addOrReplaceItemInArray(
                        this.input.update,
                        updated,
                        'id'
                    );
                }
            }
            this.emitChanges(current);
        },
        handleCreate(item) {
            this.addCreate(item);
            this.emitChanges(
                addOrReplaceItemInArray(this.value.current, item, 'temp_id')
            );
            return item;
        },
        handleUpdate(item) {
            this.setUpdate(item);
            this.emitChanges(
                arrayWithReplacedItem(
                    this.value.current,
                    this.findCurrentIndex(item),
                    item
                )
            );
        },
        handleDelete(item) {
            this.setDelete(item);
            this.emitChanges(
                arrayWithRemovedItem(
                    this.value.current,
                    this.findCurrentIndex(item)
                )
            );
        },
        addCreate(item) {
            //prevent double inserting
            this.input.create = addOrReplaceItemInArray(
                this.input.create,
                item,
                'temp_id'
            );
            return item;
        },
        setUpdate(item) {
            if (item.temp_id) {
                this.input.create = addOrReplaceItemInArray(
                    this.input.create,
                    item,
                    'temp_id'
                );
            } else {
                this.input.update = addOrReplaceItemInArray(
                    this.input.update,
                    item,
                    'id'
                );
            }
            return item;
        },
        setDelete(item) {
            if (item.temp_id) {
                this.input.create = arrayWithRemovedItem(
                    this.input.create,
                    this.input.create.findIndex(i => i.temp_id === item.temp_id)
                );
            } else {
                this.input.update = arrayWithRemovedItem(
                    this.input.update,
                    this.input.update.findIndex(i => i.id === item.id)
                );
                this.input.delete.push(item.id);
            }
        },
        findCurrentIndex(item) {
            return this.value.current.findIndex(i => {
                return (
                    (i.id && i.id === item.id) ||
                    (i.temp_id && i.temp_id === item.temp_id)
                );
            });
        },
        emitChanges(current) {
            this.$emit('input', {
                ...this.cleanInput(this.input),
                current,
            });
        },
        cleanInput(input) {
            return {
                ...input,
                update: input.update.map(o =>
                    omit(o, ['__typename', ...this.omitKeys])
                ),
                create: input.create.map(o =>
                    omit(o, ['__typename', 'temp_id', ...this.omitKeys])
                ),
            };
        },
        formErrors(item) {
            //improve this should be better...
            const formErrors = {};
            for (const [key, errors] of Object.entries(this.errors)) {
                const parts = key.split('.');
                const [inputKey, index] = parts.splice(0, 2);
                if (
                    item.id &&
                    inputKey === 'update' &&
                    this.input.update[Number(index)]?.id === item.id
                ) {
                    formErrors[parts.join('.')] = errors;
                }
                if (
                    item.temp_id &&
                    inputKey === 'create' &&
                    this.input.create[Number(index)]?.temp_id === item.temp_id
                ) {
                    formErrors[parts.join('.')] = errors;
                }
            }
            return formErrors;
        },
    },
};
