import Vue from 'vue';

function formatFields(fields, values) {
    return fields.map(field => ({
        label: field.customField.label,
        values:
            values[`field_${field.custom_field_id}`] ||
            field.customField.default_value ||
            [],
    }));
}

export default {
    name: 'CustomFieldsDisplay',

    functional: true,

    props: {
        values: Object,
        fields: Array,
    },

    render(h, { props }) {
        const $vm = new Vue();
        const customFields = formatFields(props.fields, props.values);
        return customFields.map(({ label, values }) => {
            return h(
                'div',
                {
                    class: `${$vm.$css('ws-grid')} ${label.replace(
                        /\s/g,
                        '-'
                    )}`,
                },
                [
                    h(
                        'div',
                        {
                            class: $vm.$css('ws-col-md-1/4'),
                        },
                        [h('em', {}, label)]
                    ),
                    h(
                        'div',
                        {
                            class: $vm.$css('ws-col-md-1/3'),
                        },
                        values.map(value =>
                            h('div', { style: 'white-space: pre-wrap;' }, value)
                        )
                    ),
                ]
            );
        });
    },
};
