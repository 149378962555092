<template>
    <table :class="$css('ws-table')" class="dayslots">
        <tbody>
            <tr v-for="day in dayRows" :key="day.dayNr">
                <td class="day" v-text="day.label"></td>
                <td
                    v-for="(slot, i) in day.slots"
                    :key="`dayslots-${day.dayNr}-${i}`"
                    :class="slot.cellClass"
                    class="slot"
                >
                    <label v-if="slot.enabled" :key="slot.value">
                        <input
                            :value="slot.value"
                            :checked="slot.selected"
                            type="checkbox"
                            @input="
                                handleInput(slot.value, $event.target.checked)
                            "
                        />
                        {{ slot.label }}
                    </label>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import PickerComponent from '@/components/StartLessonSlots/PickerComponent';
import HasManyModel from '@/mixins/HasManyModel';
import { dayLabels, daySlotLabels } from '@/types/PupilLessonSlot';

export default {
    name: 'GenericDaySlots',

    extends: PickerComponent,

    mixins: [HasManyModel],

    data: () => ({
        mode: 'generic_day_slots',
    }),

    computed: {
        dayRows() {
            const sorted = sortBy(this.formStartLessonSlotConfigs, [
                'day',
                'time_from',
            ]);
            const grouped = groupBy(sorted, c => `day${c.day}`);
            return Object.entries(dayLabels(this))
                .map(([dayNr, dayLabel]) => {
                    const configs = grouped[dayNr] || [];
                    const slots = Object.entries(
                        daySlotLabels(this, false)
                    ).map(([slotTimes, slotLabel]) => {
                        const enabled = configs.some(
                            c => `${c.time_from}-${c.time_till}` === slotTimes
                        );
                        const value = `${dayNr}|${slotTimes}|${this.courseId}|${this.locationId}`;
                        const selected = this.selectedKeys.includes(value);
                        const cellClass = enabled
                            ? selected
                                ? 'slot-selected'
                                : ''
                            : 'slot-disabled';
                        return {
                            enabled,
                            value,
                            label: slotLabel,
                            cellClass,
                            selected,
                        };
                    });
                    return { dayNr, label: dayLabel, slots };
                })
                .filter(dayRow => dayRow.slots.some(s => s.enabled));
        },
    },

    methods: {
        handleInput(key, checked) {
            const existing = this.mappedValues[key];
            if (checked && !existing) {
                this.handleCreate(this.createStartLessonSlotFromKey(key));
                return;
            }
            if (!checked && existing) {
                this.handleDelete(existing);
            }
        },
    },
};
</script>
<style>
.dayslots .slot {
    min-width: 90px;
    text-align: center;
}

.dayslots .slot label {
    width: 100%;
    height: 100%;
    margin: 0;
}

.dayslots .slot.slot-disabled {
    background: var(--dayslot-disabled-bg, #e9ecef);
}

.dayslots .slot.slot-selected {
    background: var(--dayslot-selected-bg, #d5fee3);
}
</style>
