<template>
    <div :class="$css('ws-mt-2')">
        <div v-for="option in field.customField.options" :key="option">
            <label>
                <input
                    v-model="controlValue"
                    :value="option"
                    type="checkbox"
                    style="margin-right: 5px"
                />
                {{ option }}
            </label>
        </div>
    </div>
</template>
<script>
import Field from '@/components/fields/Field';

export default {
    name: 'FieldCheckbox',

    extends: Field,

    computed: {
        controlValue: {
            get() {
                return this.value || this.field.customField.default_value || [];
            },
            set(value) {
                this.setValue(value);
            },
        },
    },
};
</script>
