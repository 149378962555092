<template>
    <div class="ws-results">
        <div :class="$css('ws-grid')" class="leerling">
            <div :class="$css('ws-col-md-1/4')"><em>Leerling</em></div>
            <div :class="$css('ws-col-md-1/3')">
                {{ result.pupils[0].full_name }}<br />
                {{ birthDate }} <small>({{ gender }})</small>
            </div>
        </div>

        <CustomFieldsDisplay
            v-if="pupilCustomFields.length"
            :values="customFieldValues"
            :fields="pupilCustomFields"
        />

        <div :class="$css('ws-grid')" class="locatie-les">
            <div :class="$css('ws-col-md-1/4')">
                <em>Locatie en lespakket</em>
            </div>
            <div :class="$css('ws-col-md-1/3')">
                {{ location.name }} - {{ curriculum.name }}
            </div>
        </div>
        <div :class="$css('ws-grid')" class="gewenste-startdatum">
            <div :class="$css('ws-col-md-1/4')">
                <em>Gewenste startdatum</em>
            </div>
            <div :class="$css('ws-col-md-1/3')">
                {{ startDate }}
            </div>
        </div>
        <div
            v-if="result.pupils[0].registration.notes"
            :class="$css('ws-grid')"
            class="bijzonderheden"
        >
            <div :class="$css('ws-col-md-1/4')"><em>Bijzonderheden</em></div>
            <div :class="$css('ws-col-md-1/3')" style="white-space: pre-wrap">
                {{ result.pupils[0].registration.notes }}
            </div>
        </div>

        <CustomFieldsDisplay
            v-if="registrationCustomFields.length"
            :values="customFieldValues"
            :fields="registrationCustomFields"
        />

        <template v-if="result.customer.id">
            <div :class="$css('ws-grid')" class="ouder">
                <div :class="$css('ws-col-md-1/4')">
                    <em>Ouder/verzorger</em>
                </div>
                <div :class="$css('ws-col-md-1/3')">
                    {{ result.customer.full_name }}
                </div>
            </div>
            <div :class="$css('ws-grid')" class="email">
                <div :class="$css('ws-col-md-1/4')"><em>E-mailadres</em></div>
                <div :class="$css('ws-col-md-1/3')">
                    {{ result.customer.user.email }}
                </div>
            </div>
            <div :class="$css('ws-grid')" class="adres">
                <div :class="$css('ws-col-md-1/4')"><em>Adres</em></div>
                <div :class="$css('ws-col-md-1/3')">
                    {{ result.customer.street }}
                    {{ result.customer.house_number }}<br />
                    {{ result.customer.zipcode }} {{ result.customer.city
                    }}<br />
                    <template v-if="result.customer.country_code !== 'NL'">
                        {{ result.customer.country_code }}
                    </template>
                </div>
            </div>
            <div :class="$css('ws-grid')" class="telefooon">
                <div :class="$css('ws-col-md-1/4')"><em>Telefoon</em></div>
                <div :class="$css('ws-col-md-1/3')">
                    {{ result.customer.phone_numbers[0].number }}
                </div>
            </div>

            <CustomFieldsDisplay
                v-if="customerCustomFields.length"
                :values="customFieldValues"
                :fields="customerCustomFields"
            />

            <div :class="$css('ws-grid')" class="financieel">
                <div :class="$css('ws-col-md-1/4')"><em>Financieel</em></div>
                <div :class="$css('ws-col-md-1/3')">
                    {{ subscription.name }} - {{ paymentMethod }}<br />
                    <template v-if="result.customer.account_iban_masked">
                        {{ result.customer.account_iban_masked }} ({{
                            result.customer.account_name ||
                            result.customer.full_name
                        }})
                    </template>
                </div>
            </div>

            <CustomFieldsDisplay
                v-if="financialCustomFields.length"
                :values="customFieldValues"
                :fields="financialCustomFields"
            />
        </template>
        <p v-else :class="$css('ws-mt-4')">
            Er is een mail verstuurd naar {{ result.customer.user.email }}
            waarmee de leerling aan het bestaande account kan worden gekoppeld.
        </p>
    </div>
</template>
<script>
import moment from 'moment';
import CustomFieldsDisplay from '@/components/fields/CustomFieldsDisplay';

export default {
    name: 'ResultDisplay',

    components: { CustomFieldsDisplay },

    props: {
        result: Object,
        form: Object,
        pupilCustomFields: Array,
        customerCustomFields: Array,
        registrationCustomFields: Array,
        financialCustomFields: Array,
    },

    computed: {
        customer() {
            return this.result.customer;
        },
        pupil() {
            return this.result.pupils[0];
        },
        location() {
            return this.form.locations.find(
                l => l.id === this.pupil.registration.location_id
            );
        },
        curriculum() {
            return this.form.curricula.find(
                c => c.id === this.pupil.registration.curriculum_id
            );
        },
        subscription() {
            return this.pupil.subscriptions[0].subscription;
        },
        gender() {
            return {
                F: 'Meisje',
                M: 'Jongen',
                N: 'Neutraal',
            }[this.pupil.gender];
        },
        paymentMethod() {
            const methods = {
                manual: 'Handmatig',
                direct_debit: 'Automatische incasso',
                cash: 'Contant',
                ideal: 'iDEAL',
                bancontact: 'Bancontact',
            };
            return (
                methods[this.customer.payment_method] ||
                this.customer.payment_method
            );
        },
        birthDate() {
            return moment(this.pupil.birth_date).format('D MMMM YYYY');
        },
        startDate() {
            return moment(this.pupil.registration.start_date).format(
                'D MMMM YYYY'
            );
        },
        customFieldValues() {
            const valuesById = {};
            this.pupil.customFieldValues.forEach(
                cfv => (valuesById[`field_${cfv.custom_field_id}`] = cfv.value)
            );
            this.customer.customFieldValues?.forEach(
                cfv => (valuesById[`field_${cfv.custom_field_id}`] = cfv.value)
            );
            return valuesById;
        },
    },
};
</script>
