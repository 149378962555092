<template>
    <input
        :value="date"
        :class="`${$css('ws-bg-default')} ${$css('ws-input')}`"
        @input="picked($event.target.value)"
    />
</template>
<script>
import moment from 'moment';
import flatpickr from 'flatpickr';
import { Dutch } from 'flatpickr/dist/l10n/nl.js';
flatpickr.localize(Dutch);

export default {
    name: 'DatePicker',

    props: {
        value: { type: String, default: '' },
        altFormat: { type: String, default: 'j F Y' },
        minDate: { type: [moment, null], default: null },
        maxDate: { type: [moment, null], default: null },
        config: { type: Object, default: () => ({}) },
    },

    computed: {
        valueAsDate() {
            return moment(this.value, 'YYYY-MM-DD HH:mm:ss');
        },
        date() {
            return this.valueAsDate.toISOString();
        },
    },

    watch: {
        minDate(date, old) {
            //why does vue recalc this and trigger?
            if (date.toISOString() === old.toISOString()) {
                return;
            }
            if (date && this.valueAsDate < date) {
                this.picked(date);
            }
            this.picker.set('minDate', date ? date.toDate() : null);
        },
        maxDate(date, old) {
            //why does vue recalc this and trigger?
            if (date.toISOString() === old.toISOString()) {
                return;
            }
            if (date && this.valueAsDate > date) {
                this.picked(date);
            }
            this.picker.set('maxDate', date ? date.toDate() : null);
        },
    },

    mounted() {
        this.picker = flatpickr(this.$el, {
            altInput: true,
            altFormat: this.altFormat,
            minDate: this.minDate ? this.minDate.toDate() : null,
            maxDate: this.maxDate ? this.maxDate.toDate() : null,
            dateFormat: 'Z',
            weekNumbers: true,
            locale: {
                firstDayOfWeek: 1,
            },
            ...this.config,
        });
        this.$watch('date', value => this.picker.setDate(value));
    },

    beforeDestroy() {
        if (this.picker) {
            this.picker.destroy();
        }
    },

    methods: {
        picked(dateStr) {
            const date = moment(dateStr)
                .hours(this.valueAsDate.hours())
                .minutes(this.valueAsDate.minutes());
            this.$emit('input', date.format('YYYY-MM-DD HH:mm:ss'));
        },
    },
};
</script>
