import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client';
import { ApolloLink } from 'apollo-link';

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token';

// Http endpoint
const { graphql_server, access_token } = window.$weScore || {};
const httpEndpoint = graphql_server || process.env.VUE_APP_GRAPHQL_SERVER;
const accessToken = access_token || process.env.VUE_APP_GRAPHQL_TOKEN;

//todo throw error when no token/server?

// Middleware Link
const middlewareLink = new ApolloLink((operation, forward) => {
    if (accessToken) {
        operation.setContext({
            headers: {
                'Accept-Language': Vue.prototype.$weScore?.language || 'nl',
                [process.env.VUE_APP_JWT_REQUEST_ACCESS]: accessToken,
            },
        });
    }
    return forward(operation);
});

// Config
const defaultOptions = {
    // You can use `https` for secure connection (recommended in production)
    httpEndpoint: httpEndpoint + '?token=' + accessToken,
    // You can use `wss` for secure connection (recommended in production)
    // Use `null` to disable subscriptions
    wsEndpoint: null,
    // LocalStorage token
    tokenName: AUTH_TOKEN,
    // Enable Automatic Query persisting with Apollo Engine
    persisting: false,
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    websocketsOnly: false,
    // Is being rendered on the server?
    ssr: false,

    // Override default apollo link
    // note: don't override httpLink here, specify httpLink options in the
    // httpLinkOptions property of defaultOptions.
    link: middlewareLink,

    // Override default cache
    // cache: myCache

    // Override the way the Authorization header is set
    // getAuth: (tokenName) => ...

    // Additional ApolloClient options
    // apollo: { ... }

    // Client local data (see apollo-link-state)
    // clientState: { resolvers: { ... }, defaults: { ... } }
};

// Call this in the Vue app file
export function createProvider(options = {}) {
    // Create apollo client
    const { apolloClient, wsClient } = createApolloClient({
        ...defaultOptions,
        ...options,
    });
    apolloClient.wsClient = wsClient;

    // Create vue apollo provider
    const apolloProvider = new VueApollo({
        defaultClient: apolloClient,
        defaultOptions: {
            $query: {
                // fetchPolicy: 'cache-and-network',
            },
        },
        errorHandler(error) {
            // eslint-disable-next-line no-console
            console.log(
                '%cError',
                'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
                error.message
            );
        },
    });

    return apolloProvider;
}
