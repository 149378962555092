import keyBy from 'lodash/keyBy';
import uniqueId from 'lodash/uniqueId';

export default {
    props: {
        locationId: { type: String, required: true },
        courseId: { type: String, required: true },
        curriculumId: { type: String, required: true },
        formStartLessonSlotConfigs: { type: Array, required: true },
    },

    computed: {
        mappedValues() {
            return keyBy(
                this.value.current,
                psls =>
                    `day${psls.day}|${psls.time_from}-${psls.time_till}|${psls.course_id}|${psls.location_id}`
            );
        },
        selectedKeys() {
            return Object.keys(this.mappedValues);
        },
        formStartLessonSlotConfigsWithValue() {
            return this.formStartLessonSlotConfigs.map(config => ({
                ...config,
                value: `day${config.day}|${config.time_from}-${config.time_till}|${config.course_id}|${config.location_id}`,
            }));
        },
        mappedFormStartLessonSlotConfigs() {
            return keyBy(this.formStartLessonSlotConfigsWithValue, 'value');
        },
        selectableKeys() {
            return Object.keys(this.mappedFormStartLessonSlotConfigs);
        },
        configWatcher() {
            return this.courseId + this.curriculumId + this.locationId;
        },
    },

    watch: {
        configWatcher: {
            handler() {
                const startLessonSlots = this.formStartLessonSlotConfigs.map(
                    ({ course_id, location_id, day, time_from, time_till }) => {
                        return {
                            temp_id: uniqueId('psls_'),
                            course_id,
                            curriculum_id: this.curriculumId,
                            location_id,
                            mode: this.mode,
                            day,
                            time_from,
                            time_till,
                            priority: 0,
                        };
                    }
                );
                this.input.create = startLessonSlots;
                this.emitChanges(startLessonSlots);
            },
            immediate: true,
        },
    },

    methods: {
        handleInput(key, checked) {
            const existing = this.mappedValues[key];
            if (checked && !existing) {
                this.handleCreate(this.createStartLessonSlotFromKey(key));
                return;
            }
            if (!checked && existing) {
                this.handleDelete(existing);
            }
        },
        createStartLessonSlotFromKey(key) {
            const [dayNr, times, course_id, location_id,] = key.split('|');
            const [time_from, time_till,] = times.split('-');
            return {
                temp_id: uniqueId('psls_'),
                course_id,
                curriculum_id: this.curriculumId,
                location_id,
                mode: this.mode,
                day: Number(dayNr.replace('day', '')),
                time_from,
                time_till,
                priority: 0,
            };
        },
    },
};
