<template>
    <select
        v-model="controlValue"
        :required="field.required"
        :class="$css('ws-select')"
    >
        <option
            value=""
            v-bind="{ hidden: field.required, disabled: field.required }"
        >
            Maak een keuze
        </option>
        <option
            v-for="option in field.customField.options"
            :key="option"
            :value="option"
            v-text="option"
        ></option>
    </select>
</template>
<script>
import Field from '@/components/fields/Field';

export default {
    name: 'FieldSelect',

    extends: Field,
};
</script>
