<template>
    <DatePicker v-model="controlValue" :required="field.required" />
</template>
<script>
import Field from '@/components/fields/Field';
import DatePicker from '@/components/DatePicker';

export default {
    name: 'FieldText',

    components: { DatePicker },

    extends: Field,

    methods: {
        setValue(value) {
            //strip time component
            const [raw] = value;
            this.$emit('input', [raw.substr(0, 10)]);
        },
    },
};
</script>
