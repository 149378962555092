var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('FormRow',{attrs:{"label":_vm.$trans('Locatie', 'registration.location'),"errors":_vm.errors,"error-key":"location_id"}},[(_vm.form.locations.length === 1)?_c('strong',[_vm._v(_vm._s(_vm.form.locations[0].name))]):_vm._l((_vm.form.locations),function(location){return _c('div',{key:location.id},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.location_id),expression:"location_id"}],staticStyle:{"margin-right":"5px"},attrs:{"type":"radio"},domProps:{"value":location.id,"checked":_vm._q(_vm.location_id,location.id)},on:{"change":function($event){_vm.location_id=location.id}}}),_vm._v(" "+_vm._s(location.name)+" - "+_vm._s(location.city)+" ")])])})],2),(_vm.value.location_id)?_c('div',{class:_vm.$css('ws-grid')},[_c('div',{class:_vm.$css('ws-col-md-1/2')},[_c('FormRow',{attrs:{"label":_vm.$trans('Lespakket', 'registration.curriculum'),"errors":_vm.errors,"error-key":"curriculum_id","stacked":""}},[(_vm.selectableCurricula.length > 1)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.curriculum_id),expression:"curriculum_id"}],class:_vm.$css('ws-select'),attrs:{"required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.curriculum_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"","hidden":"hidden","disabled":"disabled"},domProps:{"textContent":_vm._s(
                            _vm.$trans(
                                'Kies een lespakket',
                                'registration.choose_curriculum'
                            )
                        )}}),_vm._l((_vm.selectableCurricula),function(curriculum){return _c('option',{key:curriculum.id,domProps:{"value":curriculum.id,"textContent":_vm._s(curriculum.name)}})})],2):_c('strong',{domProps:{"textContent":_vm._s(_vm.selectableCurricula[0].name)}})])],1),_c('div',{class:_vm.$css('ws-col-md-1/2')},[_c('FormRow',{attrs:{"label":_vm.$trans('Gewenste startdatum', 'registration.start_date'),"errors":_vm.errors,"error-key":"pupils.0.registration.start_date","stacked":""}},[_c('DatePicker',{attrs:{"min-date":_vm.minStartDate,"placeholder":_vm.$trans(
                            'Kies een datum',
                            'registration.start_date_pick'
                        )},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}}),_c('p',{class:_vm.$css('ws-my-2')},[_c('small',{domProps:{"textContent":_vm._s(
                            _vm.$trans(
                                'Dit is een start-indicatie',
                                'registration.start_date_indication'
                            )
                        )}})])],1)],1)]):_vm._e(),_c('StartLessonSlotEdit',{attrs:{"pupil-registration":_vm.value,"form":_vm.form,"errors":_vm.errors},model:{value:(_vm.pupilStartLessonSlots),callback:function ($$v) {_vm.pupilStartLessonSlots=$$v},expression:"pupilStartLessonSlots"}}),_c('FormRow',{attrs:{"label":_vm.$trans('Bijzonderheden', 'registration.notes'),"help":_vm.$trans(
                'Geef kort bijzonderheden aan die van invloed kunnen zijn op de zwemlessen',
                'registration.notes_tip'
            ),"errors":_vm.errors,"error-key":"pupils.0.registration.notes"},scopedSlots:_vm._u([{key:"default",fn:function({ hasErrors }){return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.notes),expression:"notes"}],class:[
                hasErrors ? _vm.$css('ws-is-invalid') : '',
                _vm.$css('ws-textarea'),
            ],attrs:{"cols":"30","rows":"5"},domProps:{"value":(_vm.notes)},on:{"input":function($event){if($event.target.composing)return;_vm.notes=$event.target.value}}}),(_vm.notes.length >= 2000)?_c('p',{class:`${_vm.$css('ws-my-2')} ${_vm.$css('ws-text-danger')}`,domProps:{"textContent":_vm._s(
                _vm.$trans('Maximum lengte bereikt', 'error.max_length_reached')
            )}}):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }