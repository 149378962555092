window.weScoreDataLoaded = function () {
    this.showMiddleName = false;
    this.customer.country_code = 'BE';
};

window.weScoreMapPaymentOptions = opts =>
    opts.map(o => {
        return {
            value: o.value,
            text:
                {
                    'Automatische incasso': 'Automatische domiciliëring',
                }[o.text] || o.text,
        };
    });
