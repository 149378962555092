<template>
    <textarea
        v-model="controlValue"
        :required="field.required"
        rows="5"
        :class="$css('ws-textarea')"
    ></textarea>
</template>
<script>
import Field from '@/components/fields/Field';

export default {
    name: 'FieldText',

    extends: Field,
};
</script>
