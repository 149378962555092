<template>
    <span>
        {{ textParts.before
        }}<a :href="url" target="_blank">
            <FaIcon :icon="['fas', 'external-link-alt']" class="fa-sm" />
            {{ linkText }}</a
        >{{ textParts.after }}
    </span>
</template>
<script>
export default {
    name: 'LinkText',

    props: {
        url: { type: String },
        textKey: { type: String },
        linkKey: { type: String },
    },

    data() {
        return {
            baseStrings: {
                'general.link.terms_agreed_text': this.$trans(
                    'Ik ga akkoord met de %link%.',
                    'general.link.terms_agreed_text',
                    { link: '%|%' }
                ),
                'general.link.terms_agreed_text_link': this.$trans(
                    'algemene voorwaarden',
                    'general.link.terms_agreed_text_link'
                ),
                'general.link.privacy_agreed_text': this.$trans(
                    'Ik ga akkoord met het %link%.',
                    'general.link.privacy_agreed_text',
                    { link: '%|%' }
                ),
                'general.link.privacy_agreed_text_link': this.$trans(
                    'privacy statement',
                    'general.link.privacy_agreed_text_link'
                ),
                'after_submit.link.pay_online_text': this.$trans(
                    'Direct online betalen: %link%.',
                    'general.link.privacy_agreed_text',
                    { link: '%|%' }
                ),
                'after_submit.link.pay_online_text_link': this.$trans(
                    'Naar betaling',
                    'general.link.privacy_agreed_text_link'
                ),
            },
        };
    },

    computed: {
        textParts() {
            const [before, after] = this.baseStrings[this.textKey].split('%|%');
            return { before, after };
        },
        linkText() {
            return this.baseStrings[this.linkKey];
        },
    },
};
</script>
