import Vue from 'vue';
import App from './App.vue';
import { createProvider } from './vue-apollo';
import moment from 'moment';
moment.locale('nl-NL');

import 'flatpickr/dist/flatpickr.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faExternalLinkAlt,
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import CssClassesFilter from '@/plugins/CssProvider';
import TranslateFilter from '@/plugins/Translate';

Vue.config.productionTip = false;

Vue.prototype.$weScore = window.$weScore || {
    graphql_server: '',
    access_token: '',
    css_provider: process.env.VUE_APP_CSS_PROVIDER,
    language: 'nl',
    language_strings: null,
    element_id: 'app',
};

Vue.prototype.$webRoot = process.env.VUE_APP_FORMS_SERVER;

Vue.use(CssClassesFilter, Vue.prototype.$weScore.css_provider);
Vue.use(TranslateFilter, {
    language: Vue.prototype.$weScore.language,
    language_strings: Vue.prototype.$weScore.language_strings || {},
});

library.add({ faExternalLinkAlt, faExclamationTriangle });
Vue.component('FaIcon', FontAwesomeIcon);

new Vue({
    apolloProvider: createProvider(),
    render: h => h(App),
}).$mount(`#${Vue.prototype.$weScore.element_id}`);
