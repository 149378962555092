<template>
    <FormRow
        v-if="formStartLessonSlotConfigs.length || formLocationSettings.text_availability"
        :label="
                $trans('Voorkeursdagdelen', 'registration.preferred_day_slots')
            "
        :errors="errors"
        error-key="pupils.0.registration.pupilStartLessonSlots.create"
    >
        <StartLessonSlotPicker
            ref="pickerComponent"
            :value="value"
            :start-lesson-slot-mode="startLessonSlotMode"
            :location-id="pupilRegistration.location_id"
            :course-id="pupilRegistration.course_id"
            :curriculum-id="pupilRegistration.curriculum_id"
            :form-start-lesson-slot-configs="formStartLessonSlotConfigs"
            :form-start-lesson-slots="formStartLessonSlots"
            @input="$emit('input', $event)"
        />
        <vue-markdown
            v-if="formLocationSettings.text_availability"
            :source="formLocationSettings.text_availability"
            class="ws-markdown"
        />
    </FormRow>
</template>
<script>
import StartLessonSlotPicker from '@/components/StartLessonSlots/StartLessonSlotPicker';
import FormRow from '@/components/FormRow.vue';
import VueMarkdown from '@adapttive/vue-markdown';

export default {
    name: 'StartLessonSlotEdit',

    components: {
        StartLessonSlotPicker,
        FormRow,
        VueMarkdown,
    },

    props: {
        value: { type: Object, required: true },
        pupilRegistration: { type: Object, required: true },
        errors: { type: Object, required: true },
        form: { type: Object, required: true },
    },

    computed: {
        formLocationSettings() {
            return this.form.location_settings.find(
                ls => ls.location_id === this.pupilRegistration.location_id
            );
        },
        startLessonSlotMode() {
            return (
                this.formLocationSettings?.start_lesson_slot_mode ||
                'generic_day_slots'
            );
        },
        formStartLessonSlotConfigs() {
            return this.form.formStartLessonSlotConfigs.filter(
                psls =>
                    psls.location_id === this.pupilRegistration.location_id &&
                    psls.course_id === this.pupilRegistration.course_id &&
                    psls.mode === this.startLessonSlotMode
            );
        },
        formStartLessonSlots() {
            return [];
        },
    },

    methods: {
        resetMutations() {
            this.$refs.pickerComponent.resetMutations();
        },
    },
};
</script>
