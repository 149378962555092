import bootstrapClassmapping from '@/css/bootstrap/Classmapping';
import tailwindClassmapping from '@/css/tailwind/Classmapping';
import weScoreClassmapping from '@/css/we-score/Classmapping';

class CssProvider {
    constructor(css_provider) {
        this.classmapping = {
            bootstrap: bootstrapClassmapping,
            tailwind: tailwindClassmapping,
            'we-score': weScoreClassmapping,
        };
        this.css_provider = css_provider;
    }

    classFor(baseclass) {
        return this.find(baseclass);
    }

    find(baseclass) {
        return this.classmapping[this.css_provider][baseclass] !== undefined
            ? this.classmapping[this.css_provider][baseclass]
            : baseclass;
    }
}

const CssClassesFilter = {
    install(Vue, css_provider) {
        const provider = new CssProvider(css_provider);

        Vue.$css = baseclass => provider.classFor(baseclass);
        Vue.prototype.$css = baseclass => provider.classFor(baseclass);
        Vue.prototype.$css_provider = css_provider;

        Vue.filter('css', baseclass => provider.classFor(baseclass));
    },
};

export default CssClassesFilter;
