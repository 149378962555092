function locationOptionRelations(form, location_id) {
    return form.option_relations.find(s => s.location_id === location_id);
}

function optionRelations(form, location_id) {
    return locationOptionRelations(form, location_id)?.option_relations || [];
}

function curriculumOptionRelations(form, location_id) {
    return optionRelations(form, location_id).filter(
        r => r.property === 'curricula'
    );
}

export function locationRelation(form, location_id) {
    const curriculumOptRels = curriculumOptionRelations(form, location_id);
    return curriculumOptRels.length
        ? {
              allowedProperty: 'curricula',
              property: 'location',
              property_id: Number(location_id),
              allowedValues: curriculumOptRels.map(r => String(r.property_id)),
          }
        : null;
}

export function curriculumRelation(form, location_id, curriculum_id) {
    return (
        curriculumOptionRelations(form, location_id).find(
            r => r.property_id === Number(curriculum_id)
        ) || null
    );
}
export function subscriptionRelation(form, location_id, subscription_id) {
    return (
        optionRelations(form, location_id).find(
            r =>
                r.property === 'subscriptions' &&
                r.property_id === Number(subscription_id)
        ) || null
    );
}

export function findFirstValidCurriculumId(
    form,
    location_id,
    current_value = null
) {
    const allowedIds = curriculumOptionRelations(form, location_id).map(r =>
        String(r.property_id)
    );
    if (allowedIds.includes(current_value)) {
        return current_value;
    }
    return form.curricula.find(c => allowedIds.includes(c.id)).id;
}

export function findFirstValidCourseId(form, curriculum_id) {
    const [coursePivot] = form.curricula.find(
        c => c.id === curriculum_id
    ).courses;
    return coursePivot.course.id;
}
