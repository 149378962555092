<template>
    <div class="specific-timeslots">
        <template v-for="day in dayRows">
            <div :key="`day-${day.dayNr}`" class="day"
                 v-text="day.label"></div>
            <div :key="`slots-${day.dayNr}`"
                 class="slots">
                <div
                    v-for="(slot, i) in day.slots"
                    :key="`dayslots-${day.dayNr}-${i}`"
                    :class="slot.cellClass"
                    class="slot"
                >
                    <label
                        v-if="slot.value"
                        :key="slot.value"
                    >
                        <input
                            :value="slot.value"
                            :checked="slot.selected"
                            type="checkbox"
                            @input="
                                    handleInput(slot.value, $event.target.checked)
                                "
                        />
                        <span v-text="slot.label"></span>
                    </label>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import {
    dayLabels,
    daySlotLabels,
    pupilStartLessonSlotLabel,
} from '@/types/PupilLessonSlot';
import HasManyModel from '@/mixins/HasManyModel';
import PickerComponent from '@/components/StartLessonSlots/PickerComponent';

export default {
    name: 'SpecificTimeSlots',

    extends: PickerComponent,

    mixins: [HasManyModel,],

    data() {
        return {
            mode: 'specific_time_slots',
            dayLabels: dayLabels(this),
            daySlotLabels: daySlotLabels(this),
        };
    },

    computed: {
        dayRows() {
            const sorted = sortBy(this.formStartLessonSlotConfigsWithValue, [
                'day', 'time_from',
            ]);
            const grouped = groupBy(sorted, c => `day${c.day}`);
            return Object.entries(grouped)
                .map(([dayNr, configs,]) => {
                    const slots = configs.map(config => {
                        const selected = this.selectedKeys.includes(config.value);
                        const cellClass = selected ? 'slot-selected' : '';
                        return {
                            value: config.value,
                            label: pupilStartLessonSlotLabel(this, config),
                            cellClass,
                            selected,
                        };
                    });
                    return {dayNr, label: this.dayLabels[dayNr], slots,};
                })
                .filter(dayRow => dayRow.slots.length);
        },
    },
};
</script>
<style>
.specific-timeslots {
    display: grid;
    grid-template-columns: min-content auto;
    row-gap: 0.25rem;
}

.specific-timeslots .day {
    border-top: 1px solid var(--border-color);
    padding: 0.25rem 0.5rem 0 0.5rem;
}

.specific-timeslots .slots {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    border: 1px solid var(--border-color);
    border-right: none;
    border-bottom: none;
}

.specific-timeslots .slot {
    border-right: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    display: grid;
}

.specific-timeslots .slot label {
    padding-block: 0.25rem;
    text-align: center;
    margin: 0;
}

.specific-timeslots .slot label span {
    display: inline-block;
    min-width: 90px;
    text-align: right;
}

.specific-timeslots .slot.slot-selected {
    background: var(--dayslot-selected-bg, #d5fee3);
}

@media (min-width: 576px) {
    .specific-timeslots .slots {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media (min-width: 768px) {
    .specific-timeslots .slots {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}
</style>
