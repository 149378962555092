<template>
    <div :class="$css('ws-mt-2')">
        <div v-for="option in field.customField.options" :key="option">
            <label>
                <input
                    v-model="controlValue"
                    :value="option"
                    type="radio"
                    style="margin-right: 5px"
                />
                {{ option }}
            </label>
        </div>
    </div>
</template>
<script>
import Field from '@/components/fields/Field';

export default {
    name: 'FieldRadio',

    extends: Field,
};
</script>
