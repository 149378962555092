const registrationFieldsPerCurriculum = {
    102: [42, 45], //A
    103: [43, 46], //B
    104: [44, 47], //C
    105: [50], //Volw
    146: [48], //Overl
    150: [49], //Zwv
};

const getLessonLength = lessonChoiceValue => {
    const regex = /((45|60)\smin)/;
    const m = regex.exec(lessonChoiceValue);
    return m ? m[1] : '';
};

window.weScoreFilterCustomField = function (type, field) {
    if (type === 'registration') {
        return registrationFieldsPerCurriculum[
            this.pupil.registration.curriculum_id
        ]?.includes(Number(field.custom_field_id));
    }
    return true;
};

window.weScoreFilterSubscriptionOption = function (subscription) {
    const [lessonChoiceFieldId] =
        registrationFieldsPerCurriculum[
            this.pupil.registration.curriculum_id
        ] || [];
    if (lessonChoiceFieldId) {
        const [lessonChoiceValue] =
            this.customFieldsValues[`field_${lessonChoiceFieldId}`];
        const lessonLength = getLessonLength(lessonChoiceValue);
        return lessonLength
            ? subscription.name.includes(lessonLength)
            : true;
    }
    return true;
};

window.weScoreValidateCustomField = function (field, field_value) {
    const [lessonChoiceFieldId] =
        registrationFieldsPerCurriculum[
            this.pupil.registration.curriculum_id
        ] || [];
    if (
        lessonChoiceFieldId === field.custom_field_id &&
        (!field_value.value ||
            !field_value.value.length ||
            !field_value.value[0])
    ) {
        return [`Veld ${field.customField.label} is verplicht`];
    }
    return [];
};

window.weScoreMapSubscriptionOptions = subs =>
    subs.map(s => ({ value: s.id, text: s.name }));

window.weScoreExtraFinancialText = function () {
    const subscription = this.form.subscriptions.find(
        s => s.id === this.subscription_id
    );
    const payment_method = {
        ideal: 'iDEAL',
        external: 'Verrekening via U-Pas',
    }[this.customer.payment_method];
    if (!subscription || !payment_method) {
        return '';
    }
    return `<p><i>U heeft gekozen voor het abonnement:</i> ${subscription.name}<br/><i>Het abonnementen zal voldaan worden via:</i> ${payment_method}</p>`;
};
