<template>
    <div>
        <FormRow
            v-for="field in orderedFields"
            :key="field.customField.id"
            :label="field.customField.label"
            :help="field.customField.settings.help"
            :errors="errors"
            :error-key="`field_${field.custom_field_id}`"
        >
            <component
                :is="`field-${field.customField.field_type}`"
                :value="values[`field_${field.custom_field_id}`]"
                :field="field"
                @input="setValue(field, $event)"
            ></component>
        </FormRow>
    </div>
</template>
<script>
import FormRow from '@/components/FormRow.vue';
import FieldCheckbox from '@/components/fields/FieldCheckbox.vue';
import FieldDate from '@/components/fields/FieldDate.vue';
import FieldRadio from '@/components/fields/FieldRadio.vue';
import FieldSelect from '@/components/fields/FieldSelect.vue';
import FieldText from '@/components/fields/FieldText.vue';
import FieldTextarea from '@/components/fields/FieldTextarea.vue';

export default {
    name: 'CustomFields',

    components: {
        FormRow,
        FieldCheckbox,
        FieldDate,
        FieldRadio,
        FieldSelect,
        FieldText,
        FieldTextarea,
    },

    props: {
        values: Object,
        fields: Array,
        errors: Object,
    },

    computed: {
        orderedFields() {
            return this.fields;
        },
    },

    methods: {
        setValue(field, value) {
            this.$emit('input', field, value);
        },
    },
};
</script>
