<template>
    <div>
        <FormRow
            v-model="email"
            :label="$trans('E-mailadres', 'customer.email_address')"
            :errors="errors"
            error-key="email"
            type="email"
            required
        ></FormRow>

        <p
            v-if="existingCustomer"
            :class="`${$css('ws-my-4')} ${$css('ws-text-center')}`"
        >
            <em
                v-text="
                    $trans(
                        'Dit e-mailadres is al bekend bij ons. De nieuwe leerling zal worden toegevoegd aan uw account.',
                        'customer.email_address_known_adding_pupil_to_account'
                    )
                "
            ></em
            ><br />
            <em
                v-text="
                    $trans(
                        'U ontvangt een e-mail om dit te bevestigen.',
                        'customer.email_address_known_confirmation_will_be_sent'
                    )
                "
            ></em>
        </p>
        <template v-else>
            <div :class="$css('ws-grid')">
                <div :class="namePartColClass">
                    <FormRow
                        v-model="first_name"
                        :label="$trans('Voornaam', 'customer.first_name')"
                        :errors="errors"
                        error-key="first_name"
                        type="text"
                        required
                        stacked
                    ></FormRow>
                </div>
                <div v-if="showMiddleName" :class="$css('ws-col-sm-1/3')">
                    <FormRow
                        v-model="middle_name"
                        :label="$trans('Tussenvoegsel', 'customer.middle_name')"
                        :errors="errors"
                        error-key="middle_name"
                        type="text"
                        stacked
                    ></FormRow>
                </div>
                <div :class="namePartColClass">
                    <FormRow
                        v-model="last_name"
                        :label="$trans('Achternaam', 'customer.last_name')"
                        :errors="errors"
                        error-key="last_name"
                        type="text"
                        required
                        stacked
                    ></FormRow>
                </div>
            </div>

            <FormRow label="Adres">
                <div :class="$css('ws-grid')">
                    <div :class="$css('ws-col-sm-2/3')">
                        <FormRow
                            v-model="street"
                            :placeholder="$trans('Straat', 'customer.street')"
                            :errors="errors"
                            error-key="street"
                            type="text"
                            required
                            stacked
                        ></FormRow>
                    </div>
                    <div :class="$css('ws-col-sm-1/3')">
                        <FormRow
                            v-model="house_number"
                            :placeholder="
                                $trans('Huisnummer', 'customer.house_number')
                            "
                            :errors="errors"
                            error-key="house_number"
                            type="text"
                            required
                            stacked
                        ></FormRow>
                    </div>
                </div>
                <div :class="$css('ws-grid')">
                    <div :class="$css('ws-col-sm-1/3')">
                        <FormRow
                            v-model="zipcode"
                            :placeholder="
                                $trans('Postcode', 'customer.zipcode')
                            "
                            class="mb-0"
                            :errors="errors"
                            error-key="zipcode"
                            type="text"
                            required
                            stacked
                        ></FormRow>
                    </div>
                    <div :class="$css('ws-col-sm-2/3')">
                        <FormRow
                            v-model="city"
                            :placeholder="$trans('Plaats', 'customer.city')"
                            class="mb-0"
                            :errors="errors"
                            error-key="city"
                            type="text"
                            required
                            stacked
                        ></FormRow>
                    </div>
                </div>
                <FormRow
                    :errors="errors"
                    error-key="country_code"
                    :row-class="$css('ws-mt-4')"
                    stacked
                >
                    <select
                        v-model="country_code"
                        :class="$css('ws-select')"
                        required
                    >
                        <option
                            value="NL"
                            v-text="
                                $trans('Nederland', 'form.country.netherlands')
                            "
                        ></option>
                        <option
                            value="DE"
                            v-text="$trans('Duitsland', 'form.country.germany')"
                        ></option>
                        <option
                            value="BE"
                            v-text="$trans('België', 'form.country.belgium')"
                        ></option>
                    </select>
                </FormRow>
            </FormRow>

            <FormRow
                v-model="phone_number"
                :label="$trans('Telefoonnummer', 'customer.phone_number')"
                :errors="errors"
                error-key="phone_number"
                type="text"
                required
            ></FormRow>
        </template>
    </div>
</template>
<script>
import FormRow from '@/components/FormRow.vue';

export default {
    name: 'CustomerForm',

    components: {
        FormRow,
    },

    props: {
        value: Object,
        errors: Object,
        existingCustomer: Boolean,
        showMiddleName: Boolean,
    },

    computed: {
        first_name: {
            get() {
                return this.value.first_name;
            },
            set(first_name) {
                this.$emit('input', { ...this.value, first_name });
            },
        },
        middle_name: {
            get() {
                return this.value.middle_name;
            },
            set(middle_name) {
                this.$emit('input', { ...this.value, middle_name });
            },
        },
        last_name: {
            get() {
                return this.value.last_name;
            },
            set(last_name) {
                this.$emit('input', { ...this.value, last_name });
            },
        },
        email: {
            get() {
                return this.value.email;
            },
            set(email) {
                this.$emit('input', { ...this.value, email });
            },
        },
        street: {
            get() {
                return this.value.street;
            },
            set(street) {
                this.$emit('input', { ...this.value, street });
            },
        },
        house_number: {
            get() {
                return this.value.house_number;
            },
            set(house_number) {
                this.$emit('input', { ...this.value, house_number });
            },
        },
        zipcode: {
            get() {
                return this.value.zipcode;
            },
            set(zipcode) {
                this.$emit('input', { ...this.value, zipcode });
            },
        },
        city: {
            get() {
                return this.value.city;
            },
            set(city) {
                this.$emit('input', { ...this.value, city });
            },
        },
        country_code: {
            get() {
                return this.value.country_code;
            },
            set(country_code) {
                this.$emit('input', { ...this.value, country_code });
            },
        },
        phone_number: {
            get() {
                return this.value.phone_numbers[0].number;
            },
            set(number) {
                this.$emit('input', {
                    ...this.value,
                    phone_numbers: [{ ...this.value.phone_numbers[0], number }],
                });
            },
        },
        namePartColClass() {
            return {
                [this.$css('ws-col-sm-1/3')]: this.showMiddleName,
                [this.$css('ws-col-sm-1/2')]: !this.showMiddleName,
            };
        },
    },

    methods: {
        fieldErrors(key) {
            return this.errors[key] || [];
        },
    },
};
</script>
