<template>
    <div>
        <vue-markdown v-if="form.text_conditions" class="ws-markdown"
        >{{ form.text_conditions }}
        </vue-markdown>

        <FormRow
            v-if="form.terms_url"
            :errors="errors"
            error-key="terms_agreed"
            row-class=""
        >
            <label>
                <input
                    id="terms_agreed"
                    v-model="terms_agreed"
                    type="checkbox"
                    style="margin-right: 5px"
                />
                <LinkText
                    :url="form.terms_url"
                    text-key="general.link.terms_agreed_text"
                    link-key="general.link.terms_agreed_text_link"
                />
            </label>
        </FormRow>

        <FormRow
            v-if="form.privacy_url"
            :errors="errors"
            error-key="privacy_agreed"
            row-class=""
        >
            <label>
                <input
                    id="privacy_agreed"
                    v-model="privacy_agreed"
                    type="checkbox"
                    style="margin-right: 5px"
                />
                <LinkText
                    :url="form.privacy_url"
                    text-key="general.link.privacy_agreed_text"
                    link-key="general.link.privacy_agreed_text_link"
                />
            </label>
        </FormRow>
    </div>
</template>
<script>
import VueMarkdown from '@adapttive/vue-markdown';
import FormRow from '@/components/FormRow.vue';
import LinkText from '@/components/LinkText';

export default {
    name: 'AgreeForm',

    components: {
        LinkText,
        FormRow,
        VueMarkdown,
    },

    props: {
        value: Object,
        errors: Object,
        form: Object,
    },

    computed: {
        terms_agreed: {
            get() {
                return this.value.terms_agreed;
            },
            set(terms_agreed) {
                this.$emit('input', { ...this.value, terms_agreed });
            },
        },
        privacy_agreed: {
            get() {
                return this.value.privacy_agreed;
            },
            set(privacy_agreed) {
                this.$emit('input', { ...this.value, privacy_agreed });
            },
        },
    },
};
</script>
