<template>
    <input
        v-model="controlValue"
        :required="field.required"
        type="text"
        :class="$css('ws-input')"
    />
</template>
<script>
import Field from '@/components/fields/Field';

export default {
    name: 'FieldText',

    extends: Field,
};
</script>
