import moment from 'moment';

window.weScoreDataLoaded = function () {
    this.maxAge = { years: 4, months: 0 };
    this.pupil.registration.start_date = moment()
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
    this.subscription_id = this.form.subscriptions[0].id;
    this.customer.payment_method = 'ideal';
};
