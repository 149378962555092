export default {
    'ws-bg-default': 'bg-white',
    'ws-text-danger': 'text-danger',
    'ws-text-right': 'text-right',
    'ws-text-center': 'text-center',
    'ws-text-md-right': 'text-md-right',
    'ws-table': 'table table-sm',
    'ws-grid': 'row',
    'ws-col-1/3': 'col-4',
    'ws-col-sm-1/2': 'col-sm-6',
    'ws-col-sm-1/3': 'col-sm-4',
    'ws-col-sm-2/3': 'col-sm-8',
    'ws-col-md-1/2': 'col-md-6',
    'ws-col-md-1/3': 'col-md-4',
    'ws-col-md-2/3': 'col-md-8',
    'ws-col-md-1/4': 'col-md-3',
    'ws-my-2': 'my-2',
    'ws-my-4': 'my-4',
    'ws-mt-2': 'mt-2',
    'ws-mt-4': 'mt-4',
    'ws-form-group': 'form-group',
    'ws-form-label': 'col-form-label',
    'ws-input': 'form-control',
    'ws-select': 'form-control',
    'ws-textarea': 'form-control',
    'ws-is-invalid': 'is-invalid',
    'ws-button': 'btn btn-primary',
};
