<template>
    <div>
        <div :class="$css('ws-grid')">
            <div :class="namePartColClass">
                <FormRow
                    v-model="first_name"
                    :label="$trans('Voornaam', 'pupil.first_name')"
                    :errors="errors"
                    error-key="pupils.0.first_name"
                    type="text"
                    required
                    stacked
                ></FormRow>
            </div>
            <div v-if="showMiddleName" :class="$css('ws-col-sm-1/3')">
                <FormRow
                    v-model="middle_name"
                    :label="$trans('Tussenvoegsel', 'pupil.middle_name')"
                    :errors="errors"
                    error-key="pupils.0.middle_name"
                    type="text"
                    stacked
                ></FormRow>
            </div>
            <div :class="namePartColClass">
                <FormRow
                    v-model="last_name"
                    :label="$trans('Achternaam', 'pupil.last_name')"
                    :errors="errors"
                    error-key="pupils.0.last_name"
                    type="text"
                    required
                    stacked
                ></FormRow>
            </div>
        </div>
        <div :class="$css('ws-grid')">
            <div :class="$css('ws-col-md-1/2')">
                <FormRow
                    :label="$trans('Geboortedatum', 'pupil.birth_date')"
                    :errors="errors"
                    error-key="pupils.0.birth_date"
                    stacked
                >
                    <BirthDate
                        v-model="birth_date"
                        :min-age="minAge"
                        :max-age="maxAge"
                        :field-errors="errors['pupils.0.birth_date']"
                        required
                    />
                </FormRow>
            </div>
            <div :class="$css('ws-col-md-1/2')">
                <FormRow
                    :label="$trans('Geslacht', 'pupil.gender')"
                    :errors="errors"
                    error-key="pupils.0.gender"
                    stacked
                >
                    <select
                        v-model="gender"
                        :class="$css('ws-select')"
                        required
                    >
                        <option
                            value=""
                            disabled
                            hidden
                            v-text="
                                $trans('Maak een keuze', 'form.select_option')
                            "
                        ></option>
                        <option value="M" v-text="pupilGenderMale"></option>
                        <option value="F" v-text="pupilGenderFemale"></option>
                        <option value="N" v-text="pupilGenderNeutral"></option>
                    </select>
                </FormRow>
            </div>
        </div>
    </div>
</template>
<script>
import BirthDate from '@/components/BirthDate.vue';
import FormRow from '@/components/FormRow.vue';

export default {
    name: 'PupilForm',

    components: {
        BirthDate,
        FormRow,
    },

    props: {
        value: { type: Object, required: true },
        errors: { type: Object, required: true },
        form: { type: Object, required: true },
        showMiddleName: { type: Boolean, default: false },
        minAge: {
            type: Object,
            default: () => ({ years: null, months: null }),
        },
        maxAge: {
            type: Object,
            default: () => ({ years: null, months: null }),
        },
    },

    computed: {
        pupilGenderMale() {
            return this.form.settings.adult_audience
                ? this.$trans('Man', 'pupil.gender.adult_male')
                : this.$trans('Jongen', 'pupil.gender.child_male');
        },
        pupilGenderFemale() {
            return this.form.settings.adult_audience
                ? this.$trans('Vrouw', 'pupil.gender.adult_female')
                : this.$trans('Meisje', 'pupil.gender.child_female');
        },
        pupilGenderNeutral() {
            return this.$trans('Neutraal', 'pupil.gender.neutral');
        },
        first_name: {
            get() {
                return this.value.first_name;
            },
            set(first_name) {
                this.$emit('input', { ...this.value, first_name });
            },
        },
        middle_name: {
            get() {
                return this.value.middle_name;
            },
            set(middle_name) {
                this.$emit('input', { ...this.value, middle_name });
            },
        },
        last_name: {
            get() {
                return this.value.last_name;
            },
            set(last_name) {
                this.$emit('input', { ...this.value, last_name });
            },
        },
        birth_date: {
            get() {
                return this.value.birth_date;
            },
            set(birth_date) {
                this.$emit('input', { ...this.value, birth_date });
            },
        },
        gender: {
            get() {
                return this.value.gender;
            },
            set(gender) {
                this.$emit('input', { ...this.value, gender });
            },
        },
        namePartColClass() {
            return {
                [this.$css('ws-col-sm-1/3')]: this.showMiddleName,
                [this.$css('ws-col-sm-1/2')]: !this.showMiddleName,
            };
        },
    },

    methods: {
        fieldErrors(key) {
            return this.errors[`pupils.0.${key}`] || [];
        },
    },
};
</script>
