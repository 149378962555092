export default {
    props: {
        value: { type: Array, default: () => null },
        field: Object,
    },

    computed: {
        controlValue: {
            get() {
                return (
                    (this.value ||
                        this.field.customField.default_value || [''])[0] || ''
                );
            },
            set(value) {
                this.setValue([value]);
            },
        },
    },

    methods: {
        setValue(value) {
            this.$emit('input', value);
        },
    },
};
